import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { useAppContext } from "./AppContext";
import { generateRedirectToPath } from "./utils";
import FullScreenLoader from "./components/FullScreenLoader";

// Authentication pages
const AuthPages = {
  ForgotPassword: lazy(() => import("./pages/Authentication/ForgotPassword") /* webpackChunkName: "auth-pages" */),
  ResetPassword: lazy(() => import("./pages/Authentication/ResetPassword") /* webpackChunkName: "auth-pages" */),
  ConfirmSignUp: lazy(() => import("./pages/Authentication/ConfirmSignUp") /* webpackChunkName: "auth-pages" */),
  SignIn: lazy(() => import("./pages/Authentication/SignIn") /* webpackChunkName: "auth-pages" */),
  SignUp: lazy(() => import("./pages/Authentication/SignUp") /* webpackChunkName: "auth-pages" */)
};

// Main pages
const DocumentsPage = lazy(() => import("./pages/DocumentsPage") /* webpackChunkName: "document-pages" */);
const DocumentPage = lazy(() => import("./pages/DocumentPage") /* webpackChunkName: "document-pages" */);

// Account related pages
const AccountPage = lazy(() => import("./pages/AccountPage") /* webpackChunkName: "account-pages" */);
const ManageAccountPage = lazy(() => import("./pages/ManageAccountPage") /* webpackChunkName: "account-pages" */);
const ManageAccountsPage = lazy(() => import("./pages/ManageAccountsPage") /* webpackChunkName: "account-pages" */);

// Investment related pages
const InvestmentFlowPage = lazy(() => import("./pages/InvestmentFlowPage") /* webpackChunkName: "investment-pages" */);
const ManageInvestorsPage = lazy(() => import("./pages/ManageInvestorsPage") /* webpackChunkName: "investment-pages" */);
const ManageInvestorPage = lazy(() => import("./pages/ManageInvestorPage") /* webpackChunkName: "investment-pages" */);

// Offer related pages
const ManageOfferPage = lazy(() => import("./pages/ManageOfferPage") /* webpackChunkName: "offer-pages" */);
const ManageOfferGroupPage = lazy(() => import("./pages/ManageOfferGroupPage") /* webpackChunkName: "offer-pages" */);
const OfferPage = lazy(() => import("./pages/OfferPage") /* webpackChunkName: "offer-pages" */);
const PreviewOfferPage = lazy(() => import("./pages/PreviewOfferPage") /* webpackChunkName: "offer-pages" */);
const ReferralAgentOfferPage = lazy(() => import("./pages/ReferralAgentOfferPage") /* webpackChunkName: "offer-pages" */);

// Transaction related pages
const ManageTransactionsPage = lazy(() => import("./pages/ManageTransactionsPage") /* webpackChunkName: "transaction-pages" */);
const ManageTransactionPage = lazy(() => import("./pages/ManageTransactionPage") /* webpackChunkName: "transaction-pages" */);

// Other pages
const HomePage = lazy(() => import("./pages/HomePage") /* webpackChunkName: "home-page" */);
const MarketplacePage = lazy(() => import("./pages/MarketplacePage") /* webpackChunkName: "marketplace-page" */);
const AuditPage = lazy(() => import("./pages/AuditPage") /* webpackChunkName: "audit-page" */);
const CustomPage = lazy(() => import("./pages/CustomPage") /* webpackChunkName: "custom-page" */);
const ManageUserPage = lazy(() => import("./pages/ManageUserPage") /* webpackChunkName: "user-page" */);

export const App: React.FC = () => {
  const { loading, currentUser, currentAccount } = useAppContext();

  useEffect(() => {
    const disableNumberInputScrolling = function () {
      if ((document.activeElement as any).type === "number") {
        (document.activeElement as any).blur();
      }
    };

    document.addEventListener("wheel", disableNumberInputScrolling);

    return () => {
      document.removeEventListener("wheel", disableNumberInputScrolling);
    };
  }, []);

  const [showLoader, setShowLoader] = useState(loading)
  useEffect(() => {
    let timeout: any;
    if (loading) {
      setShowLoader(true)
    } else {
      timeout = setTimeout(() => {
        setShowLoader(false)
      }, 1500)
    }
    return () => {
      clearTimeout(timeout);
    }
  }, [loading])

  const redirectTo = new URLSearchParams(location.search).get("redirectTo");

  if (currentUser && redirectTo) {
    location.href = decodeURIComponent(redirectTo);
  }

  return showLoader || (currentUser && redirectTo) ? (
    <FullScreenLoader />
  ) : (
    <Router>
      <Suspense fallback={<FullScreenLoader />}>
        {!currentUser ? (
          <Switch>
            <Route
              exact
              path="/login"
              render={() => (
                <Redirect
                  to={
                    location.search?.includes("register=1")
                      ? "register"
                      : `/signin${generateRedirectToPath({
                          withTempPassword: false,
                        })}`
                  }
                />
              )}
            />
            <Route exact path="/signin">
              <AuthPages.SignIn />
            </Route>
            <Route exact path="/register/:accountType?" component={AuthPages.SignUp} />
            <Route exact path="/forgot-password">
              <AuthPages.ForgotPassword />
            </Route>
            <Route exact path="/reset-password" component={AuthPages.ResetPassword} />
            <Route exact path="/verify-email" component={AuthPages.ConfirmSignUp} />

            {/* Compatibility with exiting links from WB */}
            <Route exact path="/offerings" render={() => <Redirect to="/offers" />} />
            <Route
              exact
              path="/offering/:symbol"
              render={({
                match: {
                  params: { symbol },
                },
              }) => <Redirect to={`/offers/${symbol}`} />}
            />
            <Route exact path="/offers" component={MarketplacePage} />
            <Route exact path="/market/:groupId" component={MarketplacePage} />
            <Route exact path="/offers/:symbol/:tab?" component={OfferPage} />
            <Route
              exact
              path="/:slug(terms|privacy-policy|crowdfunding-rdi)"
              component={CustomPage}
            />
            <Route
              path="*"
              render={({}) => (
                <Redirect
                  to={`/signin${generateRedirectToPath({
                    withTempPassword: false,
                  })}`}
                />
              )}
            />
          </Switch>
        ) : !currentAccount ? (
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="*">
              <Redirect to={`/${window.location.search}`} />
            </Route>
          </Switch>
        ) : ["individual_investor", "entity_investor", "joint_investor"].includes(
            currentAccount.type
          ) ? (
          <Switch>
            <Route path="/account" component={AccountPage} />
            <Route path="/transactions/:transactionId" component={InvestmentFlowPage} />
            <Route exact path="/offers/:symbol/invest" component={InvestmentFlowPage} />
            <Route exact path="/offers/:symbol/:tab?" component={OfferPage} />
            <Route
              exact
              path="/documents/:type(account_document|portfolio_document|offer_document|transaction_document|report_of_exempt_distribution)/:documentId"
              component={DocumentPage}
            />
            <Route
              exact
              path="/documents/:type?/:documentId?"
              component={DocumentsPage}
            />
            <Route
              exact
              path="/:slug(terms|privacy-policy|crowdfunding-rdi)"
              component={CustomPage}
            />
            <Route exact path="/:tab(market)/:groupId?" component={HomePage} />
            <Route exact path="/:tab?" component={HomePage} />
            <Route path="*">
              <Redirect to={`/${window.location.search}`} />
            </Route>
          </Switch>
        ) : ["individual_referral_agent", "entity_referral_agent"].includes(
            currentAccount.type
          ) ? (
          <Switch>
            <Route path="/account" component={AccountPage} />
            <Route path="/offers/:symbol/:tab?" component={ReferralAgentOfferPage} />
            <Route
              exact
              path="/:slug(terms|privacy-policy|crowdfunding-rdi)"
              component={CustomPage}
            />
            <Route
              exact
              path="/:tab(referable-offers|referred-accounts)"
              component={HomePage}
            />
            <Route exact path="/:tab?" component={HomePage} />
            <Route path="*">
              <Redirect to={`/${window.location.search}`} />
            </Route>
          </Switch>
        ) : currentAccount.type === "issuer" ? (
          <Switch>
            <Route path="/preview/offers/:symbol/:tab?" component={PreviewOfferPage} />
            <Route path="/account" component={AccountPage} />
            <Route
              path="/manage/(investors|accounts)/:accountId"
              component={ManageInvestorPage}
            />
            <Route
              path="/manage/:tab(investors|investments|offer-engagements)"
              component={ManageInvestorsPage}
            />
            <Route path="/manage/offers/:symbol/:tab?" component={ManageOfferPage} />
            <Route
              path="/manage/offer-groups/:groupId/:tab?"
              component={ManageOfferGroupPage}
            />
            <Route path="/manage/transactions/:id" component={ManageTransactionPage} />
            {currentUser.canViewTransactions && (
              <Route path="/manage/transactions" component={ManageTransactionsPage} />
            )}
            <Route path="/offers/:symbol/invest" component={InvestmentFlowPage} />
            <Route path="/offers/:symbol/:tab?" component={OfferPage} />
            <Route
              exact
              path="/documents/:type(account_document|portfolio_document|offer_document|transaction_document)/:documentId"
              component={DocumentPage}
            />
            <Route exact path="/documents/:type?" component={DocumentsPage} />
            <Route path="/:tab?" component={HomePage} />
            <Route
              exact
              path="/:slug(terms|privacy-policy|crowdfunding-rdi)"
              component={CustomPage}
            />
            <Route path="*">
              <Redirect to={`/${window.location.search}`} />
            </Route>
          </Switch>
        ) : currentAccount.type === "admin" ? (
          <Switch>
            <Route path="/audit" component={AuditPage} />
            <Route path="/account" component={AccountPage} />
            {currentUser.canViewAccounts && (
              <Route
                path="/manage/accounts/:accountId/:tab?"
                component={ManageAccountPage}
              />
            )}
            {currentUser.canViewAccounts && (
              <Route path="/manage/users/:id" component={ManageUserPage} />
            )}
            <Route
              path="/manage/:tab(accounts|notes|users|wallets|offer-engagements)"
              component={ManageAccountsPage}
            />
            <Route path="/manage/offers/:symbol/:tab?" component={ManageOfferPage} />
            <Route
              path="/manage/offer-groups/:groupId/:tab?"
              component={ManageOfferGroupPage}
            />
            <Route exact path="/transaction/create" component={ManageTransactionPage} />
            <Route
              path="/manage/transactions/:id/:tab?"
              component={ManageTransactionPage}
            />
            {currentUser.canViewTransactions && (
              <Route path="/manage/transactions" component={ManageTransactionsPage} />
            )}
            <Route path="/transactions/:transactionId" component={InvestmentFlowPage} />
            <Route path="/offers/:symbol/invest" component={InvestmentFlowPage} />
            <Route path="/preview/offers/:symbol/:tab?" component={PreviewOfferPage} />
            <Route path="/offers/:symbol/:tab?" component={OfferPage} />
            <Route
              exact
              path="/documents/:type(account_document|portfolio_document|offer_document|transaction_document|generated_document)/:documentId"
              component={DocumentPage}
            />
            <Route path="/documents/:type?/:documentId?" component={DocumentsPage} />
            <Route
              exact
              path="/:slug(terms|privacy-policy|crowdfunding-rdi)"
              component={CustomPage}
            />
            <Route path="/:tab?" component={HomePage} />
            <Route path="*">
              <Redirect to={`/${window.location.search}`} />
            </Route>
          </Switch>
        ) : (
          <>Something went wrong.</>
        )}
      </Suspense>
    </Router>
  );
};
